customOptionsDirective.$inject = ["urlHelper"];
import customOptionsTemplate from '/scripts/_partials/custom-options/templates/customOptions.html';

/* @ngInject */
function customOptionsDirective(urlHelper) {
  return {
    scope: {
      productId: '<',
      initFn: '&',
      changeFn: '&'
    },
    replace: true,
    templateUrl: customOptionsTemplate,
    controller: 'CustomOptionsCtrl',
    controllerAs: 'customOptions',
    bindToController: true
  };
}
export { customOptionsDirective };