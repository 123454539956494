import appDependency from "../../../scripts/appDependency.js";
import '../../../styles/common/icons.scss';
import '../../../fonts/fonts.funnel.css';
import 'normalize.css';
import 'flexboxgrid';
import '../frontend/_common/flexboxgrid.ext.scss';
import '../../../styles/common/custom-input.scss';
import '../../../styles/views/product.scss';
import '../../../styles/common/connector.scss';
import tabsModule from '../../../scripts/_common/tabs/tabs.module.js';
appDependency.addItem(tabsModule);
import carouselModule from '../../../scripts/_common/carousel/carousel.module.js';
appDependency.addItem(carouselModule);
import ratingModule from '../../../scripts/_common/rating/rating.module.js';
appDependency.addItem(ratingModule);
import '../../../scripts/_common/spinbox/spinbox.module.js';
appDependency.addItem('spinbox');
import '../../../scripts/_common/transformer/transformer.module.js';
appDependency.addItem('transformer');
import iframeResponsiveModule from '../../../scripts/_common/iframe-responsive/iframeResponsive.module.js';
appDependency.addItem(iframeResponsiveModule);
import colorsViewerModule from '../../../scripts/_partials/colors-viewer/colorsViewer.module.js';
appDependency.addItem(colorsViewerModule);
import customOptionsModule from '../../../scripts/_partials/custom-options/customOptions.module.js';
appDependency.addItem(customOptionsModule);
import sizesViewerModule from '../../../scripts/_partials/sizes-viewer/sizesViewer.module.js';
appDependency.addItem(sizesViewerModule);
import fullHeightMobileModule from '../../../scripts/_mobile/full-height-mobile/full-height-mobile.module.js';
appDependency.addItem(fullHeightMobileModule);
import '../../../node_modules/slick-carousel/slick/slick.scss';
import '../../../node_modules/slick-carousel/slick/slick-theme.scss';
import '../frontend/vendors/slick/slick-theme.scss';
import '../frontend/_common/lp-head.scss';
import '../frontend/_common/responsive.scss';
import '../frontend/_common/text-align-adaptive.scss';
import '../frontend/_common/buttons/buttons.scss';
import '../frontend/_common/general.scss';
import '../frontend/_common/lp-table.scss';
import '../frontend/_common/lp-input.scss';
import '../frontend/_common/lp-form/styles/lp-form.scss';
import '../frontend/_common/lp-link.scss';
import '../frontend/_common/error.scss';
import '../frontend/_common/scroll-to-block/style/style.scss';
import '../frontend/_common/lp-accordion/lp-accordion.scss';
import '../frontend/_common/carousel.scss';
import '../frontend/blocks/lp-header/lp-header.scss';
import '../frontend/blocks/menu-header/menu-header.scss';
import '../frontend/blocks/covers/styles/covers.scss';
import '../frontend/blocks/booking/styles/booking.scss';
import '../frontend/blocks/columns/columns.scss';
import '../frontend/blocks/image/image.scss';
import '../frontend/blocks/text/text.scss';
import '../frontend/blocks/countdown/styles/countdown.scss';
import '../frontend/blocks/video/video.scss';
import '../frontend/blocks/form/form.scss';
import '../frontend/blocks/footer/styles/footer.scss';
import '../frontend/blocks/partners-logo/partners-logo.scss';
import '../frontend/blocks/reviews/styles/reviews.scss';
import '../frontend/blocks/reviews-singleton/styles/reviews-singleton.scss';
import '../frontend/blocks/products-view/styles/products-view.scss';
import '../frontend/blocks/products-by-category/product-by-category.scss';
import '../frontend/blocks/properties/properties.scss';
import '../frontend/blocks/social/social.scss';
import '../frontend/blocks/price/price.scss';
import '../frontend/blocks/products-view/styles/products-view.scss';
import '../frontend/blocks/contacts/contacts.scss';
import '../frontend/blocks/delimiter/delimiter.scss';
import '../frontend/blocks/services/services.scss';
import '../frontend/blocks/team/team.scss';
import '../frontend/blocks/characteristics/characteristics.scss';
import '../frontend/blocks/gallery/styles/gallery.scss';
import '../frontend/blocks/schedule/styles/schedule.scss';
import '../frontend/blocks/progressbar/progressbar.scss';
import '../frontend/blocks/lp-cart/lp-cart.scss';
import '../frontend/blocks/news/news.scss';
import '../frontend/blocks/exit/exit.scss';
import '../frontend/blocks/user/user.scss';
import '../../../Areas/Mobile/scripts/_common/tableResponsive/styles/tableResponsive.scss';
import '../../../vendors/lozad/lozad.custom.js';
import '../../../vendors/qazy/qazyOpt.js';
import '../../../vendors/qazy/qazyOpt.head.js';